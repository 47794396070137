// jQuery(function () {
function dm_cursor() {
    // $.fn.dm_cursor = function () {

        let $cursor_active = $('body').hasClass('custom-curser');
        let cursor = {
            delay: 8,
            _x: 0,
            _y: 0,
            endX: (window.innerWidth / 2),
            endY: (window.innerHeight / 2),
            cursorVisible: true,
            cursorEnlarged: false,
            $dot: document.querySelector('.cursor-dot'),
            $outline: document.querySelector('.cursor-dot-outline'),

            init: function () {
                // Set up element sizes
                this.dotSize = this.$dot.offsetWidth;
                this.outlineSize = this.$outline.offsetWidth;

                this.setupEventListeners();
                this.animateDotOutline();
            },

            //     updateCursor: function(e) {
            //         let self = this;

            //         console.log(e)

            //         // Show the cursor
            //         self.cursorVisible = true;
            //         self.toggleCursorVisibility();

            //         // Position the dot
            //         self.endX = e.pageX;
            //         self.endY = e.pageY;
            //         self.$dot.style.top = self.endY + 'px';
            //         self.$dot.style.left = self.endX + 'px';
            //     },

            setupEventListeners: function () {
                let self = this;

                // Anchor hovering
                document.querySelectorAll('a, input[type="submit"]').forEach(function (el) {

                    el.addEventListener('mouseover', function () {
                        self.cursorEnlarged = true;
                        self.toggleCursorSize();
                    });
                    el.addEventListener('mouseout', function () {
                        self.cursorEnlarged = false;
                        self.toggleCursorSize();
                    });
                });

                // Click events
                document.addEventListener('mousedown', function () {
                    self.cursorEnlarged = true;
                    self.toggleCursorSize();
                });
                document.addEventListener('mouseup', function () {
                    self.cursorEnlarged = false;
                    self.toggleCursorSize();
                });


                document.addEventListener('mousemove', function (e) {
                    // Show the cursor
                    self.cursorVisible = true;
                    self.toggleCursorVisibility();

                    // Position the dot
                    self.endX = e.pageX;
                    self.endY = e.pageY;
                    self.$dot.style.top = self.endY + 'px';
                    self.$dot.style.left = self.endX + 'px';
                });

                document.addEventListener('wheel', function (e) {
                    // // Show the cursor
                    self.cursorVisible = true;
                    self.toggleCursorVisibility();

                    self.endY = e.pageY;
                    self.$dot.style.top = self.endY + 'px';
                });

                // Hide/show cursor
                document.addEventListener('mouseenter', function (e) {
                    self.cursorVisible = true;
                    self.toggleCursorVisibility();
                    self.$dot.style.opacity = 1;
                    self.$outline.style.opacity = 1;
                });

                document.addEventListener('mouseleave', function (e) {
                    self.cursorVisible = true;
                    self.toggleCursorVisibility();
                    self.$dot.style.opacity = 0;
                    self.$outline.style.opacity = 0;
                });
            },

            animateDotOutline: function () {
                let self = this;

                self._x += (self.endX - self._x) / self.delay;
                self._y += (self.endY - self._y) / self.delay;
                self.$outline.style.top = self._y + 'px';
                self.$outline.style.left = self._x + 'px';

                requestAnimationFrame(this.animateDotOutline.bind(self));
            },

            toggleCursorSize: function () {
                let self = this;

                if (self.cursorEnlarged) {
                    self.$dot.style.transform = 'translate(-50%, -50%) scale(0.75)';
                    self.$dot.classList.add("hover");
                    self.$outline.style.transform = 'translate(-50%, -50%) scale(1.5)';
                    self.$outline.classList.add("hover");
                } else {
                    self.$dot.style.transform = 'translate(-50%, -50%) scale(1)';
                    self.$dot.classList.remove("hover");
                    self.$outline.style.transform = 'translate(-50%, -50%) scale(1)';
                    self.$outline.classList.remove("hover");
                }
            },

            toggleCursorVisibility: function () {
                let self = this;

                if (self.cursorVisible) {
                    self.$dot.style.opacity = 1;
                    self.$outline.style.opacity = 1;
                } else {
                    self.$dot.style.opacity = 0;
                    self.$outline.style.opacity = 0;
                }
            }
        }

        if ($cursor_active != false && false == (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))) {
            cursor.init();
        }
        
}
export { dm_cursor };

// });
