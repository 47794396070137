function dm_nav() {
    "use strict";

    let $body = $('body');
    let $prevScrollpos = window.scrollY;
    let $header = $('header#nav-header'), $header_h = $header.outerHeight(true), $admin_bar_h = $body.hasClass('admin-bar') ? 32 : 0;
    let $nav_menu_cont = $header.find('.nav-menu-cont'), $nav_menu_cont_h = $header.find('.nav-menu-cont').outerHeight(true);
    let $scroll_trigger = $header_h - $nav_menu_cont_h;

    console.log('>> dm_nav loaded')

    $(window).on('scroll', debounce(function () {
        let $currentScrollPos = window.scrollY;

        // Deal with showing the nav on scroll up
        if ($currentScrollPos < 0 || ($prevScrollpos > $currentScrollPos && $currentScrollPos <= $admin_bar_h + $scroll_trigger)) { // Top
            $body.css('padding-top', 0);
            $header.removeClass('fixed').removeClass('scrolling-up').removeClass('scrolling-down').addClass('scrolled-top');
            $nav_menu_cont.removeAttr('style');
        } else if ($prevScrollpos > $currentScrollPos && $currentScrollPos > $admin_bar_h) { // scrolling up
            $body.css('padding-top', $nav_menu_cont_h);
            $header.addClass('fixed').removeClass('scrolled-top').removeClass('scrolling-down').addClass('scrolling-up');
            $nav_menu_cont.css('top', 0);
        } else if ($currentScrollPos > $header_h) { // scrolling down
            $body.css('padding-top', $nav_menu_cont_h);
            $header.addClass('fixed').removeClass('scrolling-up').removeClass('scrolled-top').addClass('scrolling-down');
            $nav_menu_cont.css('top', -$nav_menu_cont_h);
        }
        $prevScrollpos = $currentScrollPos;

    }, 5));

    function debounce(func, wait) {
        let timeout;
        return function () {
            let context = this, args = arguments;
            let later = function () {
                timeout = null;
                func.apply(context, args);
            };
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
        };
    }

}

export { dm_nav };