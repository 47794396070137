// jQuery(function() { 

import { Slick } from "slick-carousel";
function dm_carousel() {

    // $.fn.dm_carousel = function () {

        let $carousel = $('.carousel');

        let $_slickOptions = {
            mobileFirst: true,
            infinite: true,
            autoplay: false,
            speed: 950,
            slidesToShow: 1,
            centerMode: true,
            centerPadding: '60px',
            variableWidth: false,
            arrows: true,
            dots: true,
            prevArrow: '<a class="slick-prev"><i class="fas fa-chevron-left"></i></a>',
            nextArrow: '<a class="slick-next"><i class="fas fa-chevron-right"></i></a>',
            touchMove: true,
            draggable: true,
            responsive: [{
                breakpoint: 991, //769
                settings: 'unslick'
            }]
        };

        if ( $carousel.length ){
            console.log('>> slick')
            $carousel.slick($_slickOptions);

        }
    // };

// });
}
export { dm_carousel };
