// jQuery(function() { 
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger.js";
function dm_content_with_list() {

    let $cwl = $('.content-with-list');

    if ( $cwl.length ){
        const pinnedElement = document.querySelector(".pin-elem");
        const pinnedAgainst = document.querySelector(".pin-against");
        const pinnedElementHeight = pinnedElement.clientHeight;
        const pinnedAgainstHeight = pinnedAgainst.clientHeight;
        const h3Elements = pinnedAgainst.querySelectorAll("h3");
        const scrollProgressContainer = document.querySelector(".scroll-progress");
        let h3_side_left = true; // alternate sides


        h3Elements.forEach((h3, index) => {
            const distanceFromTop = h3.offsetTop;
            const percentagePosition = (distanceFromTop / pinnedAgainstHeight) * 100;
            const label = document.createElement("div");

            label.classList.add("scroll-label");
            const words = h3.textContent.split(" ");
            if (words.length) {
                if ( words[0] == 'Development' ){
                    words[0] = 'Dev';
                }
                label.innerHTML = `${words.shift()} <span class="d-none d-lg-block">${words.join(" ")}</span>`;
            }
            label.style.position = "absolute";
            label.style.top = `${percentagePosition}%`;

            if ( h3_side_left == true ){
                h3_side_left = false;
                label.classList.add("scroll-label-left");
            } else {
                h3_side_left = true;
                label.classList.add("scroll-label-right");
            }

            // label.style.left = "0"; // Adjust left position if needed
    
            scrollProgressContainer.appendChild(label);
        });

        // keep a record of labels created
        const newLabels = pinnedElement.querySelectorAll(".scroll-label");

        gsap.to(".pin-elem", {
            scrollTrigger: {
                trigger: ".pin-elem", 
                start: "top-=75px top",
                endTrigger: ".pin-against", 
                end: `bottom-=${pinnedElementHeight}px top`,
                pin: true, 
                pinSpacing: false,
                markers: false,
            },
        });

        gsap.to(".progress-hightlight", {
            scrollTrigger: {
                id: "progressHighlightAnimation",
                trigger: ".pin-elem", // Start tracking from the point when the pin starts
                start: "top-=75px top", // Start exactly when the pinning begins
                endTrigger: ".pin-against", // End at the same trigger as when the pin ends
                end: `bottom-=${pinnedElementHeight}px top`,
                scrub: true, // Makes it smooth and linked to the scroll
                onUpdate: self => {
                    const progress = (self.progress * 100).toFixed(2);
                    document.querySelector('.progress-hightlight').style.height = progress + '%';
                    document.querySelector('.indicator').style.top = progress + '%';
                    newLabels.forEach((label_added, index) => {
                        if ( progress > label_added.style.top ){
                            label_added.classList.add("highlight");
                        } else {
                            label_added.classList.remove("highlight");
                        }
                    });
                },
            }
        });
    }

}
export { dm_content_with_list };
