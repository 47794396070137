import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger.js";
import Cookies from 'js-cookie';
import { dm_carousel } from './carousel';
import { dm_cursor } from './cursor';
import { dm_reveal } from './scroll_reveal';
import { dm_parallax } from './parallax';
import { dm_content_with_list } from './content_with_list';

function dm_overlay() {
    const $body = $('body');
    const $overlay = $('.overlay');
    const $overlay_inner = $overlay.find('.overlay-inner');
    const $page = $('body > .page');
    const $cursor_dot = $('.cursor-dot');
    const $header = $('header');
    const $hamburger = $header.find('.hamburger');
    const $nav = $header.find('nav #navbarNavDropdown');


    $body.on('click', 'a:not(.no-overlay)', function ($event) {
        const $url = $(this).attr("href");
        const $dm_mode = Cookies.get('dm-dark-mode') || 'light';

        console.log('clicked link:', $url);
        console.log('dark mode:', $dm_mode);

        if ($url === '#' || !$url) {
            console.log('URL not found or is # - skipping:', $url);
            return true;
        }

        if ($(this).hasClass('blog-item')) {
            console.log('handling .blog-item click');
            $event.preventDefault();
            return false;
        }

        $event.preventDefault();

        if (window.location.href === $url) {
            console.log('current URL matches clicked URL - no action');
            $event.preventDefault();
            return false;
        }

        console.log('triggering AJAX for URL:', $url);
        $.ajax({
            url: $url,
            type: 'POST',
            data: {
                action: 'load_content',
                ajax_load: true,
                dm_mode: $dm_mode
            },
            success: function ($response) {
                console.log('AJAX success');
                const $cursor_dot_position = $cursor_dot.position();

                console.log('cursor position:', {'top':$cursor_dot_position.top, 'left':$cursor_dot_position.left});
                $overlay.css({
                    top: $cursor_dot_position.top,
                    left: $cursor_dot_position.left,
                    width: $cursor_dot.width(),
                    height: $cursor_dot.height()
                }).show().addClass('scale');

                setTimeout(() => {
                    $page.hide().html($response).fadeIn('fast');
                    setTimeout(() => { 
                        $overlay.removeClass('scale');
                    }, 250);
                    $hamburger.removeClass('open');
                    $nav.addClass('collapse');
                    $body.removeClass('menu-open');

                    if ($body.find('.header-background-image').length) {
                        $body.addClass('header-bg-image');
                    } else {
                        $body.removeClass('header-bg-image');
                    }

                    const $title = $('body > .page > .page-inner').data('meta-title');
                    window.history.pushState({}, $title, $url);
                    document.title = $title;

                    setTimeout(() => {
                        dm_cursor();
                        dm_carousel();
                        dm_reveal();
                        dm_parallax();
                        dm_content_with_list();
                        // $.fn.headerMinimise();
                        window.scrollTo(0, 0);
                        if ($('section.blog').length) {
                            let $wpurl = $('section.blog').attr('data-wpurl');
                            $.fn.blog($wpurl);
                        }

                        // reset contact CTA
                        $('section.contact-cta').find('.scroll-reveal-done').removeClass('scroll-reveal-done');
                    }, 250);

                    setTimeout(() => { // delay matches css transition time
                        $overlay.removeAttr('style');
                    }, 1000);


                    console.log('Content updated');
                }, 500);

                $(window).trigger('resize');//.scrollTop(0);
                

                if (window.location.hostname === 'www.digital-mosaic.co.uk') {
                    console.log('Tracking virtual pageview for:', $url);
                    window.dataLayer.push({
                        'event': 'pageview',
                        'virtualUrl': $url
                    });
                }
            },
            error: function () {
                console.error("AJAX load error");
            }
        });

        return false;
    });

    $(window).on('popstate', function () {
        const $url = window.location.href;
        $.ajax({
            url: $url,
            type: 'POST',
            data: { ajax_load: true },
            success: function ($response) {
                $page.fadeOut('fast', function () {
                    $page.html($response).fadeIn('fast');
                    setTimeout(() => {
                        dm_cursor();
                        dm_carousel();
                        dm_reveal();
                        dm_content_with_list();
                    }, 250);
                });
            }
        });
    });
}

export { dm_overlay };
