// jQuery(function() { 
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger.js";
function dm_reveal() {

    // $.fn.dm_reveal = function () {

        let $body = $('body');
        reset_scroll_triggers();

        console.log(">> dm_reveal ");

        function animateFrom(elem, direction, spin) {
            if ( elem.classList.contains('no-scroll-reveal') || elem.classList.contains('scroll-reveal-done') ) return;

            direction = direction || 1;
            let x = 0,
                y = direction * 100,
                rotate = spin ? direction * 7 : 0;
        
            elem.style.transform = "translate(" + x + "px, " + y + "px) ";
            elem.style.opacity = "0";
            elem.style.transform = "scale(0.75) scale(0.3)" ;

            gsap.fromTo(elem, {x: x, y: y, autoAlpha: 0,transform:"scale(0.75) scale(0.3)"}, {
                duration: 1.25, 
                x: 0,
                y: 0, 
                autoAlpha: 1,
                transform: "scale(1) scale(1)",
                ease: "expo", 
                overwrite: "auto"
            });

            elem.classList.add("scroll-reveal-done");

        }
        
        function hide(elem) {
            gsap.set(elem, {autoAlpha: 0});
        }

        function reset_scroll_triggers(){
            console.log(">> reset_scroll_triggers ")
            $(window).trigger('resize');
            ScrollTrigger.killAll();
            ScrollTrigger.refresh();
        } 

        gsap.utils.toArray($('section').find("h1,h2,h3,h4,h5,h6,p,li,a,button,img,video,.reveal")).forEach(function(elem, i) {
            if ( $(elem).hasClass('no-scroll-reveal') || $(elem).parent().hasClass('no-scroll-reveal') || $(elem).parent().parent().hasClass('no-scroll-reveal') || $(elem).closest('div').hasClass('slick-slider') ) return; 

            $(elem).css('transition', 'none');

            hide(elem); // assure that the element is hidden when scrolled into view
            
            let spin = true;
            if ( $(elem).get(0).tagName == 'IMG' || $(elem).get(0).tagName == 'VIDEO' || $(elem).get(0).tagName == 'SVG' ){
                spin = false;
            }

            ScrollTrigger.create({
                trigger: elem,
                onEnter: function() { animateFrom(elem, 1, spin) }, 
                onEnterBack: function() { animateFrom(elem, -1, spin) },
                onLeave: function() { /*hide(elem)*/ }, 
                overwrite: "all"
            });

        });
    // };
    // });
}
export { dm_reveal };