const $isDesktop = window.matchMedia("(min-width: 768px)").matches;

//import 'bootstrap';
// import 'bootstrap/js/src/modal';
// import 'bootstrap/js/src/tooltip';
// import 'bootstrap/js/src/collapse';
import $ from 'jquery';
import 'jquery.easing';
window.$ = window.jQuery = $;
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger.js";
import { Slick } from "slick-carousel";
import Cookies from 'js-cookie';

import {dm_nav} from './nav';
import {dm_blog} from './blog';
import {dm_carousel} from './carousel';
import {dm_cursor} from './cursor';
import {dm_reveal} from './scroll_reveal';
import {dm_parallax} from './parallax';
import {dm_content_with_list} from './content_with_list';
import {dm_overlay} from './overlay';

jQuery(function() {
    let $ww = $(window).width(), $is_mobile = false, $mobile_breakpoint = 768, $body = $('body');

    $.fn.dmInit = function () {

        gsap.registerPlugin(ScrollTrigger);

        let $switch_mode = $('header a.switch-mode'), $header = $('header'),
            $hamburger =  $('.nav-toggle'), $nav = $('#navbarNavDropdown'), $nav_parent_menus = $nav.find('ul > li.menu-item-has-children');

        let $switch_wrapper = $('.switch-mode-wrapper');
        $switch_wrapper.on('click', function () {
            $(this).toggleClass('dark');
            $('.switch-toggle', this).toggleClass('dark');
            $('html, body').toggleClass('dark-mode');
            if ($(this).hasClass('dark')) {
                Cookies.set('dm-dark-mode', true);
                $('section img').each(function () {
                    $(this).attr('src', $(this).data('dark-mode'));
                });
            } else {
                Cookies.set('dm-dark-mode', false);
                $('section img').each(function () {
                    $(this).attr('src', $(this).data('white-mode'));
                });
            }
        });
        if (Cookies.get('dm-dark-mode') === 'true') {
            $switch_wrapper.addClass('dark');
            $('.switch-toggle', $switch_wrapper).addClass('dark');
            $('html, body').addClass('dark-mode');
        }

        // HAMBURGER
        $hamburger.on('click', function( $event ){
            $event.preventDefault();
            $(this).toggleClass('open');
            $body.toggleClass('menu-open');
            if ( $(this).hasClass('open') ){
                $nav.removeClass('collapse');
            } else {
                $nav.addClass('collapse').removeAttr('style');
                $nav.fadeOut('slow');
                setTimeout(function(){
                    $nav_parent_menus.removeClass('open');
                }, 800);
            }
        });

        $('.nav-mask, .nav-close, .menu-item:not(.menu-item-has-children) a, .header-link').on('click', function() {
            $body.removeClass('menu-open');
            $hamburger.removeClass('open');
            $nav.addClass('collapse').removeAttr('style');
            $nav.fadeOut('slow');
            setTimeout(function(){
                $nav_parent_menus.removeClass('open');
            }, 800);
        });

        dm_nav();
        dm_carousel();
        dm_reveal();
        dm_parallax();
        dm_content_with_list();

        setTimeout(function(){
            dm_cursor();
        }, 250);

        // HASH 
        if (window.location.hash) {
            let $hash = window.location.hash.replace(/#/, '');
            let $target = $('section[data-slug="' + $hash + '"]');
            if ($target.length) {
                $('html,body').animate({
                    scrollTop: $target.offset().top
                }, 750, 'easeOutQuad');
            }
        }

        $('#navbarNavDropdown ul > li.menu-item-has-children > a').on('click', function ( $event ) {
            $event.preventDefault();
            $('#navbarNavDropdown ul > li.menu-item-has-children').toggleClass('open');
        });

        dm_overlay();
        dm_blog();

        if ($('section.blog').length) {
            let $wpurl = $('section.blog').attr('data-wpurl');
            $.fn.blog($wpurl);
        }
    }

});