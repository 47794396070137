// import {dm_reveal} from './scroll_reveal';
// jQuery(function() { 

function dm_blog() {
    $.fn.blog = function ($wpurl) {
        console.log(">> $.fn.blog")
        let $body = $("body");
        let $j_btn = $(".blog-load-more");
        let $jpage = $j_btn.data('page');
        let $blog_cont = $('.blog-data-cont'), $blog_categories_cont = $('.blog-categories .container');
        let $blog_cat_id = $blog_categories_cont.attr('data-cat-id');
        let $blog_page_count;
        let $pagination = $('nav.pagination');
        let $jcats = $('.blog-categories a');
        $pagination.hide();
        if ($blog_cont.length) {
            $blog_page_count = 1;
        }
        // if ($body.hasClass('page-template-blog')) {
        //     let $wpurl = $('section.blog').attr('data-wpurl');
        //     $.fn.blog($wpurl);
        // }

        $(document).on('click', 'a.blog-load-more',  function (event) {
            event.preventDefault();
            $('.blog-col').css('opacity',0);
            let $this = $(this),
                $jpage = parseInt($j_btn.data('page')) + 1,
                $blog_cat_id = $blog_categories_cont.attr('data-cat-id');

            $.ajax({
                url: $wpurl + "/wp-admin/admin-ajax.php",
                type: 'POST',
                data: {
                    action: "load_blogs",
                    page_no: $jpage,
                    cat_id: $blog_cat_id,
                    returnJSON: true
                },
                success: function ($response) {
                    $('h1,.section-title, .blog-categories a, .blog-categories h5, .blog-data-cont h2, .blog-data-cont a, .blog-data-cont img').removeClass('scroll-reveal-done');
                    let $html = JSON.parse($response);

                    $(".contact-cta .scroll-reveal-done, a.blog-load-more").removeClass('scroll-reveal-done');

                    $(".blog-col-0").append($html[0]);
                    $(".blog-col-1").append($html[1]);
                    $(".blog-col-2").append($html[2]);

                    let $has_more = $html['has_more'];

                    $j_btn.data('page', $jpage);
                    // setTimeout(function(){
                    //     dm_reveal();
                    // }, 410);
                    // $('h1,.section-title,.blog-item a').removeClass('scroll-reveal-done');
                    // $('.blog-item a').removeClass('scroll-reveal-done');
                    $('.blog-col').css('opacity',1);

                    if ($has_more) {
                        $("a.blog-load-more").fadeOut();
                    } else{
                        $("a.blog-load-more").fadeIn();
                    }
                }
            });
            return false;
        });

        $jcats.on('click', function (event) {
            event.preventDefault();
            let $cat_id = $(this).data('cat-id');
            $('.blog-col').css('opacity',0);
            // console.log("cat", $cat_id)
            let $blog_categories_cont = $('.blog-categories .container');
            $blog_categories_cont.find('.first-load').removeClass("first-load");
            $jcats.removeClass('active');
            $(this).addClass('active');
            $j_btn.data('page', -1);
            $blog_categories_cont.attr('data-cat-id', $cat_id);

            $blog_cont.find('.blog-item').remove();

            $('.blog-cat-text').text($(this).find('h5').text())

            $("a.blog-load-more").trigger('click');

            $("html, body").animate({ 'scrollTop': $blog_categories_cont.offset().top - 20 }, 500, 'easeOutQuad');

            setTimeout(function(){
                $('.blog-col').css('opacity',1);
            }, 410);

            return false;
        });

    };
}

export { dm_blog };

// });