// jQuery(function() { 
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger.js";
function dm_parallax() {

    // $.fn.dm_parallax = function () {
        console.log('>> dm_parallax')
        const animate = gsap.utils.toArray('.parallax'), 
            animate_flex = gsap.utils.toArray('.parallax-flex')


        animate.forEach(el=>{

            let ranNum = Math.ceil(Math.random() * 75) * (Math.round(Math.random()) ? 1 : -1);
        
            const tl = gsap.timeline({
                scrollTrigger: {
                    trigger: el,
                    start: 'top bottom',
                    end: "80%",
                    scrub: 1,
                    // markers: true,
                    // onUpdate: ({progress, direction, isActive}) => console.log(progress, direction, isActive)
                }
            })
            
            tl.to(el, {
                yPercent:ranNum,
                ease: 'Power1.InOut'
            })
        
        });

        animate_flex.forEach(el=>{

            let dir = el.getAttribute('data-dm-dir') == 'up' ? -1 : 1;
            let parallax = el.getAttribute('data-dm-parallax');

            const tl = gsap.timeline({
                scrollTrigger: {
                    trigger: el,
                    start: 'top bottom',
                    end: "100%",
                    scrub: 1,
                    // markers: true,
                    // onUpdate: ({progress, direction, isActive}) => console.log(progress, direction, isActive)
                }
            })
            
            tl.to(el, {
                yPercent: dir * parallax,
                ease: 'Power1.InOut'
            })
        
        });
    // }

// });
}
export { dm_parallax };